<template>
  <form-wizard
    ref="formWizard"
    color="#7367F0"
    :title="null"
    :subtitle="null"
    layout="vertical"
    start-index="1"
    hide-buttons
    class="wizard-vertical mb-3 steps-transparent"
  >
    <tab-content
      title="Koleksi"
    >
      Form
    </tab-content>

    <tab-content
      title="Produk"
    >
      <div class="form-row align-items-center mb-2">
        <div class="col">
          <h3>Daftar Produk <span v-if="list.length">({{ list.length }})</span></h3>
        </div>
        <div class="col-auto">
          <div class="btn btn-light">
            Batal
          </div>
        </div>
        <div class="col-auto">
          <div class="btn btn-primary">
            Simpan Koleksi
          </div>
        </div>
      </div>

      <hr>

      <div class="form-row align-items-center mb-2">
        <span class="col-auto">Tambah Produk:</span>

        <div class="col-md-5">
          <v-select
            v-model="selectedOption"
            label="name"
            class="w-fill-available"
            :options="searchOptions"
            @input="select"
            @search="searhProduct"
          >
            <template #option="item">
              <div class="d-flex align-items-center">
                <div class="flex-grow-0">
                  <img
                    :src="item.images[0].image_url | thumbnail(40)"
                    alt="Product Image"
                    width="40px"
                    height="40px"
                    class="rounded border mr-75"
                  >
                </div>
                <div class="flex-grow-1">
                  <div>
                    {{ item.name }}
                  </div>
                  <small>{{ item.brand.name }}</small>
                </div>
              </div>
            </template>
            <span
              v-if="loadingSearch"
              slot="no-options"
            >
              Memuat..
            </span>
            <span
              v-else
              slot="no-options"
            >
              Ketik untuk mencari..
            </span>
          </v-select>
        </div>

        <div class="col-auto">
          <button class="btn btn-primary">
            <feather-icon icon="PlusIcon" />
            Tambah
          </button>
        </div>
      </div>

      <div
        v-if="!list.length"
        class="p-5 text-muted text-center"
      >
        Belum ada produk yang ditambahkan.
      </div>

      <draggable
        v-model="list"
        tag="ul"
        class="list-group list-group-flush cursor-move"
      >
        <li
          v-for="(item, index) in list"
          :key="index"
          class="card shadow-none border mb-50"
        >
          <div class="card-body py-1 pr-1 pl-50">
            <div class="form-row align-items-center">
              <div class="col-auto text-muted">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="24px"
                  viewBox="0 -960 960 960"
                  width="24px"
                  fill="currentColor"
                ><path d="M360-160q-33 0-56.5-23.5T280-240q0-33 23.5-56.5T360-320q33 0 56.5 23.5T440-240q0 33-23.5 56.5T360-160Zm240 0q-33 0-56.5-23.5T520-240q0-33 23.5-56.5T600-320q33 0 56.5 23.5T680-240q0 33-23.5 56.5T600-160ZM360-400q-33 0-56.5-23.5T280-480q0-33 23.5-56.5T360-560q33 0 56.5 23.5T440-480q0 33-23.5 56.5T360-400Zm240 0q-33 0-56.5-23.5T520-480q0-33 23.5-56.5T600-560q33 0 56.5 23.5T680-480q0 33-23.5 56.5T600-400ZM360-640q-33 0-56.5-23.5T280-720q0-33 23.5-56.5T360-800q33 0 56.5 23.5T440-720q0 33-23.5 56.5T360-640Zm240 0q-33 0-56.5-23.5T520-720q0-33 23.5-56.5T600-800q33 0 56.5 23.5T680-720q0 33-23.5 56.5T600-640Z" /></svg>
              </div>
              <div class="col-auto">
                <img
                  :src="item.image_url | thumbnail(50)"
                  alt="Foto"
                  class="rounded-lg border w-50px"
                >
              </div>
              <div class="col">
                <div>{{ item.name }}</div>
                <div class="text-muted">
                  Merah
                </div>
              </div>
              <div class="col-auto">
                <button
                  class="btn btn-flat-danger btn-icon"
                  @click="deleteItem(index)"
                >
                  <feather-icon icon="TrashIcon" />
                </button>
              </div>
            </div>
          </div>
        </li>
      </draggable>
    </tab-content>
  </form-wizard>
</template>

<script>
import {
// BCard,
// BCardBody,
} from 'bootstrap-vue'
import { FormWizard, TabContent } from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import draggable from 'vuedraggable'
import vSelect from 'vue-select'

export default {
  components: {
    draggable,
    // vue form wizard
    FormWizard,
    TabContent,
    vSelect,
  },
  data() {
    return {
      selectedOption: null,
      searchOptions: [],
      loadingSearch: false,
      list: [],
    }
  },
  mounted() {
    this.$refs.formWizard.activateAll()
  },
  methods: {
    deleteItem(index) {
      this.list.splice(index, 1)
    },
    select(item) {
      this.list.unshift({
        name: item.name,
        image_url: item.images.length ? item.images[0].image_url : null,
      })

      this.selectedOption = null
    },
    searhProduct(input) {
      if (!input) {
        this.searchOptions = []
        return
      }
      this.loadingSearch = true

      this.$store.dispatch('product/search', input).then(data => {
        this.searchOptions = data
      })
        .finally(() => {
          this.loadingSearch = false
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';
@import '@core/scss/vue/libs/vue-select.scss';

.sortable-ghost {
  opacity: 0;
  transition-duration: 0s;
}
</style>
